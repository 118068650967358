
.main_div{
    gap:30px;
}
.main_card{
    
    padding :18px;
    width:25%;
    min-width: 210px;
    height:132px;
    display: flex;
    gap:24px;
    border-radius: 6px;
    cursor: pointer;

}


  .main_card:nth-child(1) {
    background-color: #7da0fa; /* Color for the first card */
  }

  .main_card:nth-child(2) {
    background-color: #4b49ac; /* Color for the first card */
  }

  .main_card:nth-child(3) {
    background-color: #7978e9; /* Color for the first card */
  }

  .main_card:nth-child(4) {
    background-color: #f3797e; /* Color for the first card */
  }

.icon_box{
    width:64px !important;
    height:64px !important;
    background-color: white;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding:10px !important;
}

.img_size{

        width: 34px;
        height: 34px;
        object-fit: contain; /* or 'contain' depending on your preference */
      
}

.content_of_card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 64px;
}

.upper_content{
    color:#ffd280;
    font-weight: 600;
    font-size: small;
}
.lower_content{
    line-height: 24px;
    color:white;
    font-size: 24px;
}





