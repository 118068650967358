.showQrCode_con {

    .resource_card {
        background-color: black;
        color: white;
        width: fit-content;
        border-radius: 6px;
        padding: 5px;

        & small {
            font-size: 10px;
        }

        .large {
            font-size: 12px;
        }
    }

    .child_image_con {
        padding: 3%;

        & img {
            width: 100px;
        }

        /* @media (max-width:500px) {
            & img {
                width: 100%
            }
        } */
    }

    /* min-height: 100vh; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */

    .detail_con {
        width: fit-content;
        font-family: Arial, Helvetica, sans-serif;
        /* text-wrap: nowrap; */
        margin: auto;
        font-size: 14px;

    }

    @media (max-width:550px){
        .detail_con{
            min-width: 80%;
        }
    }
}