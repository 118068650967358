.userHeading {
    font-size: 18px;
    
    color: #5b626b;
    

}

.AddBotHeading {
    font-size: 18px;
    margin: 0;
    line-height: 30px;
    font-weight: 700;
    color: #5b626b;
}

.AddUserBtn {
    background-color: #626ed4;
    border: 1px solid #626ed4;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    padding: 6px 12px;
    font-weight: 500;
}

.AdduserBackground {


    width: 100%;
    padding-left: 10px;
}

.inputField {
    background-color: #f5f7ff ;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* width: 100%; */
}
.Tableshadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}
.NumberInput {
    width: 90%;
    padding-left: 70px;

}
.NumberInput1 {
    width: 45%;
    padding-left: 60px;

}

.labelName {
    width: 170px;
    font-size: 14px;
    padding-left: 10px;
    color: #5b626b;
}

.FormButton {
    margin: 11px;
    gap: 10px;
}

.Submitbutton {
    background-color: #7da0fa;
    border: 1px solid #7da0fa;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
}

.cencelbutton {

    color: #fff;
    border: 1px solid #6c757d;
    background-color: #f3797e;;
    border-color: #f3797e;;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
    margin-left: 10px;
}


.BacgroundUser {
    /* background-color: #f8f8fa; */
}

.ExcelButton {
    font-size: 14px;
    color: #fff;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    border-radius: 3px;
    padding: 5px 10px;
}

.selectDrop {
    font-size: 14px !important;
    /* padding: 0px 20px; */
    width: 95px !important; 
}

.Tablerow {
    font-size: 13px !important;
    font-weight: 600 !important;
    /* width: 120px; */
}


/* .Icon {
    font-size: 16px !important;
    margin: 0 5px;
    color: #7DA0FA;
}
.Icon1 {
    font-size: 16px !important;
    margin: 0 5px;
    color: #F3797E;
}
.Icon2 {
    font-size: 16px !important;
    margin: 0 5px;
    color: #7978E9;
} */

.DltCustom {
    font-size: 14px;
    font-weight: 500 !important;

}

.DropDownWidth {
    /* width: 100px !important; */
    display: flex;

}
.ModalDlt1 {
    color: #211F1F;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.224px;
    margin-bottom: 22px;
  }
  
  .ModalDlt {
    color: #211F1F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.224px;
    margin-bottom: 22px;
  }
  
  .DelteButton {
    width: 158px;
    height: 52px;
    background-color: #FAE4EB;
    padding: 16px 32px;
    border-radius: 10px;
    color: #E03368;
    text-align: center;
    cursor: pointer;
  }
  .DelteButton:hover{
    box-shadow:3px 3px 7px 3px #888888;
  }
  
  .Allbutton {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  
  .CencelButton {
    width:158px;
    height: 52px;
    background: linear-gradient(to right, #FF84A7, #E03368);
    padding:16px 32px;
    border-radius: 10px;
    color:white;
    text-align: center;
    cursor: pointer;
  }
  .CencelButton:hover{
    background: linear-gradient(to right, #FF84A7, #E03368);
    box-shadow: -3px 3px 7px 3px #888888;
  }
  
  .nav_main{
    margin-top: 30px !important;
    gap: 15px !important;
    /* position: absolute; */
    /* top: 11%; */
  }

  .navitem{
    border:none !important;
    background-color: transparent !important;
  }

  .navitem:focus{
    color: #888888;;
    background-color:#888888;


  }
  .navitem.focused {
    color: #888888;
    background-color:#888888;

  }

  .navitem:hover{
    background-color:#888888;
  }



  .NavText{
    font-size: 15px;
    font-weight: 600;
    color: #0a0a0a !important;

}
.NavText:hover{
    color: rgb(17, 16, 16) !important;
}
.NavTab{
    background: none !important;
    border: none;
}
.Tablebackground{
    background: #1d4aff !important;
}
.SRnumber{
    width: 130px !important;
}
.tableHead{
    font-weight: 600 !important;
}
