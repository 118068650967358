.inputField {
    background-color: #f5f7ff ;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* width: 100%; */
}
.NumberInput {
    width: 90%;
    padding-left: 70px;

}
.NumberInput1 {
    width: 45%;
    padding-left: 60px;

}

.labelName {
    width: 170px;
    font-size: 14px;
    padding-left: 10px;
    color: #5b626b;
}

.FormButton {
    margin: 11px;
    gap: 10px;
}

.Submitbutton {
    background-color: #7da0fa;
    border: 1px solid #7da0fa;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
}

.cencelbutton {

    color: #fff;
    border: 1px solid #6c757d;
    background-color: #f3797e;;
    border-color: #f3797e;;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
    margin-left: 10px;
}
.tableHead{
    font-weight: 600 !important;
}
.Tableshadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}
.table_body{
    padding: 1.3rem !important;
    background-color: #f5f7ff !important;
}