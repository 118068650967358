.main_sec {
  height: 100vh;
  width: 100vw;
  background: url("../../../public/images/round.png") no-repeat center ;
  background-size: cover;


}



.center{
  width: 40%;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:grey;
}
.inner {
  width: 100%;
  
}

.upper{
  height: 140px;
  background-color: rgb(255, 255, 255);
  overflow:hidden;
  border-radius: 8px 8px 0px 0px;
  padding:10px 15px;
}

.upperheading{
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin-top:5px;
}
.upperimage{
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom:5px;
  
}

.main-img {
  size: cover;
}


.lower{
  padding:25px 75px 50px 75px;
  background: #f9f9f9;
  border-radius: 0 0 8px 8px;
}

.welcome{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .main_sec {
    display: block;
  }

  .left {
    /* height: 40vh; */
    display: none;
  }
  .right {
    width: 100%;
  }

  .inner {
    width: 80%;
  }
}

.loginbutton:hover{
background-color: lightgray !important ;
color:black;
}


