.tableHead {
  font-weight: 600 !important;
}

.ExcelButton {
  font-size: 14px;
  color: #fff;
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 3px;
  padding: 5px 10px;
}
