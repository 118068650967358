.tableHead {
  font-weight: 600 !important;
}

.container {
  width: 100%;

  overflow-x: auto;
}

.EditIcon {
  background: #7da0fa;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  border: none;
}

.inputField {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  /* width: 100%; */
}

.NumberInput {
  width: 90%;
  padding-left: 70px;
}

.labelName {
  width: 150px;
  padding-left: 10px;
  color: #5b626b;
  font-size: 14px !important;
}

.FormButton {
  margin: 11px;
  gap: 10px;
}

.style.textEditer {
  background-color: white !important;
  border: 1px solid gray;
}
