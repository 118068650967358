:root {
  --bgsidebar: #ffffff;
  --logout: grey;
}

.logout_logo_color {
  /* color:var(--logout) */
  color: #000;
}

.sidebar_main_con {
  width: 230px;
  /* transition: all 0.3s ease-out; */
  /* background-color: rgb(250, 251, 252); */

  box-shadow: 0px 0px 10px rgb(198, 198, 198);
  background: var(--bgsidebar);
  color: #ffffff;
}

.top_bar {
  height: 8vh;
  background-color: var(--bgsidebar);
  color: grey;
  box-shadow: 5px 5px 10px rgb(234, 232, 232);
  width: 100%;
}

.sidebar_item_con {
  margin-top: 15px;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  /* border: 1px solid red; */
}

.sidebaritem {
  display: flex;
  align-items: center;
  gap: 10px;
}



.sidebaritem :nth-child(1) {
  font-size: 15px;
}

.icon_size {
  font-size: 20px !important;
}

.child_con {
  height: 92vh;
  width: 100%;
  overflow-y: scroll;
}
.Buzz {
  font-size: 35px;
  font-weight: 700;
}

.sidebar_item_con {
  height: calc(100vh - 132px);
  /* height:100vh; */
  overflow-y: scroll;
}

.navLink {
  text-decoration: none !important;
  /* color: var(--logout) !important; */
  font-weight: 500;
  color: #000;
}

.text-Css {
  font-size: 15.7px !important;
}

.dimenstion_of_logo{
  width: 240px;
  height: 110px;
  background : url('../../../public/images/teenpatti.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* background: aqua; */

}