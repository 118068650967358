body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: 600;
  margin-bottom: 7px;
  font-size: 17px;
}

Button {
  text-transform: capitalize !important;
}

.bold-text-grey {
  font-weight: 500;
  font-size: 22px;
  color: #000000;
}

.cancel_btn {
  /* color: black !important;
  border: 1px solid black !important;
  background: white;
  border-radius: 4px; */
  background: #f3797e !important;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: 1px solid transparent;
}

.btn_primary {
  text-decoration: none !important;
  background: #4539ca !important;
  color: white !important;
}

.btn_primary:hover {
  text-decoration: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
}

.relative {
  position: relative;
}

.custom-btn {
  /* background: #534ba8!important;
  color: #ffffff!important;
  border: 1px solid #534ba8!important;
  border-radius: 5px; */
  background-color: #7da0fa;
  border: none;
  /* cursor: pointer; */
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
}
/* .custom-btn:disabled{
  color: red !important;
} */

.custom-btn-red {
  background-color: #ea2e4d;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
}

.bg-main {
  background: #534ba8;
}

.over-flow-hide-x {
  overflow-x: hidden;
}

.pointer {
  cursor: pointer;
}

.round-border {
  border-radius: 100%;
}

.text-gray {
  color: gray;
}
.img-btn {
  background: #f1f1f1 !important;
  color: white !important;
  padding: 15px 8px !important;
}
.img-style {
  width: 100%;
  height: 91px;
  border-radius: 5px;
}

/* sagment style */

.close-btn-position {
  position: absolute;
  right: 2px;
  cursor: pointer;
}

/* Brand style */

.select-style {
  height: 38px;
}
.select-style1 {
  height: 50px;
  /* padding-top: -5px; */
  /* padding-bottom: 5px; */
}

/* Model Style */

.w-5 {
  width: 5%;
}
.w-12 {
  width: 35px;
}

.text_cap {
  text-transform: capitalize !important;
}

/* upload Icon box style */

.box_style {
  width: 100px !important;
  height: 100px !important;
}
.navLink {
  text-decoration: none;
  color: black;
}

.active {
  color: #4b49ac !important;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found {
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.not-found h1 {
  font-size: 3em;
  color: #f00;
  margin: 0;
}

.not-found p {
  font-size: 1.5em;
}

.not-found h1 {
  animation: shake 0.5s;
}
.child_con {
  background-color: #f8f8fa;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.searchInput {
  border-radius: 5px;
  border: 1px solid rgb(200, 199, 199);
}

.labelCss {
  margin-right: 10px;
  font-size: 14px;
  color: #5b626b;
}

.mainHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: baseline;
}

.tableHeading{
  color: #000;
  font-size: 22px !important;
  font-weight: 500;
}
.table_background {
  background-color: #f5f7ff !important;
}
.delete {
  background-color: #f3797e;
  padding: 7px 5px;
  color: white;
  font-size: 12px !important;
  border-radius: 10px;
  border: none;
}
.EditIcon {
  background: #7da0fa;
  padding: 7px 5px;
  color: white;

  border-radius: 10px;
  border: none !important;
}
.view {
  background-color: #4b49ac;
  padding: 7px 5px;
  font-size: 12px !important;
  color: white;
  border-radius: 10px;
  border: none;
}
.Yes {
  font-size: 14px;
  font-weight: 500;
}

/* active {
  font-weight: 800;
  color: blue !important;
} */

.rdw-dropdown-selectedtext {
  text-decoration: none !important;
  color: black !important;
  display: flex !important;
  position: relative !important;
  height: 100% !important;
  align-items: center !important;
  padding: 0 5px !important;
}


.MuiTablePagination-selectLabel {
  margin:0px;
}
.MuiTablePagination-displayedRows{
  margin:0px;
}
.jodit-status-bar-link{
  display: none !important;
}
.table{
  margin-bottom: 0 !important;
} 