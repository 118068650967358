.tableHead {
    font-weight: 600 !important;
  }
  .icondiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  .delete_icon{
    background-color: #f3797e;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  border: none;
  }
  .EditIcon{
    background: #7da0fa;
  padding: 5px 10px;
  color: white;
  border-radius: 10px;
  border: none;
  }
  .userHeading {
    font-size: 18px;
    margin: 0;
    line-height: 30px;
    font-weight: 700;
    color: #6c757d;

}

.AddBotHeading {
    font-size: 18px;
    margin: 0;
    line-height: 30px;
    font-weight: 700;
    color: #5b626b;
}

.AddUserBtn {
    background-color: #626ed4;
    border: 1px solid #626ed4;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    padding: 6px 12px;
    font-weight: 500;
}

.AdduserBackground {
  
    width: 100%;
    padding-left: 10px;
}

.inputField {
    background-color: #f5f7ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.NumberInput {
    width: 90%;
    padding-left: 70px;
}

.labelName {
    width: 100px;
    padding-left: 10px;
    color: #5b626b;
}

.FormButton {
    margin: 11px;
    gap: 10px;
}

.Submitbutton {
    background-color: #626ed4;
    border: 1px solid #626ed4;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 7px;
}

.cencelbutton {
    color: #fff;
    border: 1px solid  #6c757d;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 7px;
    margin-left: 10px;
}
.TableUser{
margin: 15px 0;
    background-color: white;
}
.BacgroundUser{
    background-color: #f8f8fa;
}   